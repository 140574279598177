import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { AuthServices } from "./auth.service";
import { Injectable } from "@angular/core";
import { endpoints } from "../_config/url-endpoint";

/**
 *
 *
 * @export
 * @class AllBookingServices
 */
@Injectable({
  providedIn: "root",
})
export class AllBookingServices {
  private endTripFlag = new Subject<any>();
  private tripData = new Subject<any>();
  private tripMetricData = new Subject<any>();
  private currentLocationMetricData = new Subject<any>();
  private tripId = new Subject<any>();
  private tripDetailsData: any = {};
  private tripMetricDetailsData: any = {};

  /**
   * Creates an instance of AllBookingServices.
   *
   * @param {HttpClient} http
   * @param {AuthServices} authService
   * @memberof AllBookingServices
   */
  constructor(private http: HttpClient, private authService: AuthServices) {}

  /**
   * method to set trip id to observable
   *
   * @param {*} data
   * @memberof AllBookingServices
   */
  public setTripId(data: any) {
    this.tripId.next({ result: data });
  }

  /**
   * the method returns observable for the trip, listened by many components
   *
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  public getTripId(): Observable<any> {
    return this.tripId.asObservable();
  }

  /**
   * get list of trips to be displayed
   *
   * @param {*} queryString
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  getTripList(queryString: any): Observable<any> {
    let url = `${endpoints.allBookings.getTripUrl}?${queryString}`;
    return this.http.get(url, this.authService.getAccessHeaders());
  }

  /**
   * Get Trip header count of trip list
   *
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  tripHeaderViewCountList(tenantId): Observable<any> {
    let tenantUrlString = '';
    if(tenantId) {
      tenantUrlString = `?tenantId=${tenantId}`;
    }
    const url = `${endpoints.allBookings.tripCountUrl}${tenantUrlString}`;
    return this.http.get(url);
  }

  /**
   * Download Trip Data in excel file
   *
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  downloadBookingExcelFile(queryString): Observable<any> {
    const url = `${endpoints.allBookings.tripDownloadUrl}?${queryString}`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(url, { headers, responseType: "blob" });
  }

  /**
   *
   *
   * @param {string} estimationId
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  getTripEstimationDetailsByEstimationId(
    estimationId: string,
    carTypeId: string
  ): Observable<any> {
    let payload = '';
    if (carTypeId) {
      payload = `?carTypeId=${carTypeId}`;
    }
    const url = `${endpoints.allBookings.estimationDetailUrl}/${estimationId}${payload}`;
    return this.http.get(url);
  }

  /**
   * Get Trip details by trip Id
   *
   * @param {*} tripId
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  getTripDetailsByTripId(tripId): Observable<any> {
    const url = `${endpoints.allBookings.tripDetailUrl}/${tripId}`;
    return this.http.get(url);
  }

  /**
   *
   *
   * @param {*} tripId
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  getTripMetricsByTripId(tripId): Observable<any> {
    const url = `${endpoints.allBookings.tripMetricsUrl}?tripId=${tripId}`;
    return this.http.get(url);
  }

  /**
   * Set Trip data for child component
   *
   * @param {*} data
   * @memberof AllBookingServices
   */
  public setTripData(data: any) {
    this.tripDetailsData = { result: data };
    this.tripData.next({ result: data });
  }

  /**
   * Set Trip Metric data for child component
   *
   * @param {*} data
   * @memberof AllBookingServices
   */
  public setTripMetricData(data: any) {
    this.tripMetricDetailsData = { result: data };
    this.tripMetricData.next({ result: data });
  }

  /**
   *
   *
   * @param {*} data
   * @memberof AllBookingServices
   */
  public setCurrentLocationMetric(data: any) {
    this.currentLocationMetricData.next({ result: data });
  }

  /**
   * Get Trip Data in child table
   *
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  public getTripData(): Observable<any>{
    return this.tripData.asObservable();
  }

  public getTripDetailsData(): any {
    return this.tripDetailsData;
  }
  /**
   * Get Trip Metric Data in child table
   * 
   * @returns {*}
   */
  public getTripMetricDetailsData(): any {
    return this.tripMetricDetailsData;
  }

  /**
   * Get Trip Metric in child table
   *
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  public getTripMetricData(): Observable<any> {
    return this.tripMetricData.asObservable();
  }

  /**
   * Get Current location Metric in child table
   *
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  public getCurrentLocationMetricData(): Observable<any> {
    return this.currentLocationMetricData.asObservable();
  }

  /**
   * Get Trip Transaction details by trip Id
   *
   * @param {*} tripId
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  getTransactionLogs(tripId): Observable<any> {
    const url = `${endpoints.allBookings.tripTransactionLogsUrl}?tripId=${tripId}`;
    return this.http.get(url);
  }

  /**
   * Get Trip Logs for Trip Breakdown by trip Id
   *
   * @param {*} tripId
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  getTripBreakDown(tripId): Observable<any> {
    const url = `${endpoints.allBookings.tripBreakdownLogsUrl}/${tripId}`;
    return this.http.get(url);
  }

  convertToGPSCoordinates(latitude: number, longitude: number): string {
    const latDegrees = Math.floor(latitude);
    const latMinutes = Math.floor((latitude - latDegrees) * 60);
    const latSeconds = (
      (latitude - latDegrees - latMinutes / 60) *
      3600
    ).toFixed(2);

    const lonDegrees = Math.floor(longitude);
    const lonMinutes = Math.floor((longitude - lonDegrees) * 60);
    const lonSeconds = (
      (longitude - lonDegrees - lonMinutes / 60) *
      3600
    ).toFixed(2);

    const latitudeStr = `${latDegrees}° ${latMinutes}' ${latSeconds}" N`;
    const longitudeStr = `${lonDegrees}° ${lonMinutes}' ${lonSeconds}" W`;

    return `${latitudeStr} ${longitudeStr}`;
  }

  public endTip(options): Observable<any> {
    return this.http.post(endpoints.allBookings.endTripUrl, options);
  }

  /**
   *
   *
   * @param {{
   *     tripId: string;
   *   }} options
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  public getAllTripNotifications(options: {
    tripId: string;
    perPage: number;
    pageNumber: number;
  }): Observable<any> {
    return this.http.get(
      `${endpoints.allBookings.allTripNotificationUrl}?tripId=${options.tripId}&perPage=${options.perPage}&pageNumber=${options.pageNumber}`
    );
  }

  /**
   * Get Call back notification check
   *
   * @param {{
   *     tripId: string;
   *     userId: string;
   *     type: string;
   *   }} options
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  public getCallBackNotificationCheck(options: {
    tripId: string;
    userId: string;
    type: string;
    isPush: boolean;
  }): Observable<any> {
    return this.http.get(
      `${endpoints.allBookings.callBackCheckNotificationUrl}?tripId=${options.tripId}&userId=${options.userId}&type=${options.type}`
    );
  }

  public setRefreshBooking(data: any) {
    this.endTripFlag.next({ result: data });
  }

  public getRefreshBooking(): Observable<any> {
    return this.endTripFlag.asObservable();
  }

  /**
   * Get Trip header count of trip list
   *
   * @return {*}  {Observable<any>}
   * @memberof AllBookingServices
   */
  tripHeaderViewScheduledCountList(tripType, tenantId): Observable<any> {
    let tenantUrlString = '';
        if(tenantId) {
            tenantUrlString = `?tenantId=${tenantId}`;
        }
    const url = `${
      endpoints.allBookings.scheduledTripCountUrl + `/${tripType}${tenantUrlString}`
    }`;
    return this.http.get(url);
  }

  /**
   *
   *
   * @param {*} tripId
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  getTripChat(tripId): Observable<any> {
    const url = `${endpoints.allBookings.tripChatUrl}?tripId=${tripId}`;
    return this.http.get(url);
  }

  /**
   *
   *
   * @param {{
   *     tripId: string;
   *   }} options
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  public fetchRequestProcessData(options: {
    tripId: string;
    perPage: number;
    pageNumber: number;
  }): Observable<any> {
    // &perPage=${options.perPage}&pageNumber=${options.pageNumber}
    return this.http.get(
      `${endpoints.allBookings.tripRequestProcessUrl}?tripId=${options.tripId}&perPage=${options.perPage}&pageNumber=${options.pageNumber}`
    );
  }

  public changeTripCarCategory(options): Observable<any> {
    const urlStr = endpoints.allBookings.changeCarCategoryUrl;
    return this.http.post(urlStr, options);
  }

  public changeTripTime(options): Observable<any> {
    const urlStr = endpoints.allBookings.changeTripTimeUrl;
    return this.http.post(urlStr, options);
  }

  public getCustomerCollector(tripId: string) {
    const url = `${endpoints.allBookings.customerCollectorUrl}/${tripId}`;
    return this.http.get(url);
  }

  public getChauffeurDirectionLink(tripId: string) {
    const url = `${endpoints.allBookings.chauffeurDirectionLinkUrl}/${tripId}`;
    return this.http.get(url);
  }

   /**
   * Get Call back notification check
   *
   * @param {{
   *     tripId: string;
   *     userId: string;
   *     type: string;
   *     isMqtt: boolean,
   *     requestId: string
   *   }} options
   * @returns {Observable<any>}
   * @memberof AllBookingServices
   */
  public getMqttCallBackNotificationCheck(options: {
    tripId: string;
    userId: string;
    type: string;
    isMqtt: boolean,
    requestId: string
  }): Observable<any> {
    return this.http.get(
      `${endpoints.allBookings.callBackCheckNotificationUrl}?tripId=${options.tripId}&userId=${options.userId}&type=${options.type}&isMqtt=${options.isMqtt}&requestId=${options.requestId}`
    );
  }

}
