<header>

  <span *ngIf="showGoBackItem" class="go-back" (click)="goBack()"><img src="./../assets/images/left.svg" alt="logout">
    Go
    back</span>

  <div class="city-select" *ngIf="showTenantDropdown && role!=='PARTNER'">
    <select aria-label="Select City" class="form-select" (change)="tenantChange($event)">
      <option *ngFor="let tenant of tenantData" [value]="tenant.id" [selected]="tenant.id === tenantId">
        {{tenant.fullName}}</option>
    </select>
  </div>

  <!-- <ul class="action-button-wrap">
    <li class="notifications" (click)="notificationPage()">
      <img src="{{notificationsIcon}}" alt="notificationsIcon">
      <span *ngIf="totalNewNotification > 0" class="bazed"></span>
    </li>
  </ul> -->
</header>

<ng-template #template>
  <div class="modal-body">
    <img class="icon" src="./../assets/images/image-icon-big.svg" alt="logout">
    <h3 class="alertText">Confirm Logout?</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <span class="alertContent">Are you sure you want to log out of your account?</span>
    <div style="display: flex;">
      <a class="button-secondry" (click)="modalRef.hide()"> {{'button.cancel' | translate }}</a>
      &nbsp;&nbsp;
      <a class="btn-confirm " (click)="logout()">CONFIRM</a>
    </div>
  </div>
</ng-template>