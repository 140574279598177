import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../../shared-components/success-modal/success-modal.component'
import * as $ from 'jquery';

@Component({
  selector: 'app-customers-edit-modal',
  templateUrl: './customers-edit-modal.component.html',
  styleUrls: ['./customers-edit-modal.component.scss']
})
export class CustomersEditModalComponent implements OnInit {

  @Input() fromParent;
  @ViewChild('successPopup') successPopup: TemplateRef<any>;
  public customerForm: FormGroup;
  public disableUpdateBtn = false;
  public disableCreateBtn = false;
  public disableDeleteBtn = false;
  public img64: any;
  public uploadfile: string;
  public uploadfileUpdate: any;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode = false;
  public modalRef: BsModalRef;
  constructor(
    public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private modalService: BsModalService, private modal: NgbModal
  ) { }

  ngOnInit() {
    this.customerForm = this.formBuilder.group({
      userImage: ['', [Validators.required]],
      fullName: [this.fromParent.fullName, [Validators.required]],
      email: [this.fromParent.email, [
        Validators.required,
        Validators.email,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')
      ]],   
      phoneNumber: [this.fromParent.mobileNumber, [Validators.required]],
      role: ['', [Validators.required]],
      contract: ['' ,[Validators.required]],
      country: [this.fromParent.country, [Validators.required]],
      city: [this.fromParent.city, [Validators.required]],
    });
    /* Output:
     {prop1: "Some Data", prop2: "From Parent Component", prop3: "This Can be anything"}
    */
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  public picked(event, type) {
    const sizeByte = $('#fileupload')[0].files[0].size;
    const sizeKb = sizeByte / 1024;

    const extension: string = $('#fileupload').val().replace(/^.*\./, '');
    const ext: string = extension.toUpperCase();

    if (ext !== 'JPG' && ext !== 'JPEG' && ext !== 'PNG') {
      $('#file_detail').html('File format should be (JPG/JPEG and PNG)');
      if(type === 'create') {
       this.disableCreateBtn = true;
      } else {
        this.disableUpdateBtn = true;
      }
    } else if (sizeKb > 500) {
      $('#file_detail').html('File size maximum 500KB');
      if(type === 'create') {
        this.disableCreateBtn = true;
       } else {
         this.disableUpdateBtn = true;
       }
    } else {
      $('#file_detail').html('');
      this.disableCreateBtn = false;
      this.disableUpdateBtn = false;
      const fileList: FileList = event.target.files;

      if (fileList.length > 0) {
        const file: File = fileList[0];
        // this.img64 = file;
        // this.uploadfile = file.name;
        // this.uploadfile1 = file.name;
        this.handleInputChange(file,type);
      } else {
        alert('No file selected');
      }
    }
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    const base64result = reader.result; // .substr(reader.result.indexOf(',') + 1);
    this.img64 = base64result;
   
  }

  handleInputChange(files,type) {
    const file = files;
   
    const pattern = /image-*/;
    const reader = new FileReader();
    if(type === 'create') {
      this.uploadfile = file.name;
      this.uploadfileUpdate = ''
    } else {
      this.uploadfileUpdate = file.name;
      this.uploadfile = ''
    }
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  
  customerSubmit(value){
    const res = {
      head : 'Partner Added.',
      content: 'Partner successfully added. An invite was sent to <span>alison@email.com</span>. The account will be activated upon first login.'
    };

    
    this.modal.dismissAll();
    // this.modalRef = this.modalService.show(this.successPopup, {
    //   class: 'alert-modal modal-250 modal-dialog-centered',
    // });
    const modalRef = this.modal.open(SuccessModalComponent,
      {
        scrollable: true,
        windowClass: 'success-modal',
        
        // keyboard: false,
        // backdrop: 'static'
      });

 
    modalRef.componentInstance.fromParent = res;
    modalRef.result.then((result) => {
     
    }, (reason) => {
    });
  }

}
