import { RouterModule, Routes } from "@angular/router";

import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: "terms-conditions",
    loadChildren: () =>
      import("./terms-conditions/terms.conditions.module").then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: "otp/:requestId/:userId/:email",
    loadChildren: () => import("./otp/otp.module").then((m) => m.OtpModule),
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
