<div class="modal-header">
  <h5 class="modal-title">Update Customer</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="customerForm" (ngSubmit)="customerSubmit(customerForm.value)">
    <div class="row">
      <div class="col-6 pr-4">
      
        <div class="clearfix"></div>
        <input id="fileupload" style="display: none;" (change)="picked($event,'create')" type="file" name="userImage"
          formControlName="userImage" class="browse-file" />
        <label for="fileupload" class="upload pl-0">
          <div for="fileupload" class="droparea">
            <div class="img-upload">
              <div class="img-up" *ngIf="!uploadfile">
                <span><img src="./../assets/images/edit-display-picture.svg" alt="uploadIcon"></span>
              </div>
              <!-- <div class="" *ngIf="uploadfile">
                <div id="spanfile" class="mt-2">{{uploadfile}}</div>
              </div> -->
              <div *ngIf="uploadfile" class="check"><img src="{{img64}}" alt="check"></div>                      
            </div>
            <div class="text-upload">
              <h6>Upload Profile Picture</h6>
              <p>.jpeg or png. Max 500 KB</p>
              <!-- <div class="col-11 pl-0" *ngIf="uploadfile">
                <div id="spanfile" class="mt-2">{{uploadfile}}</div>
              </div> -->

            </div>
          </div>
        </label>
        <h6 id="file_detail" class="error"></h6>
        <div class="clearfix"></div>
      </div>                
   
</div>
        <div class="row">
          <div class="col-6 pb-3 left-p">
            <label for="ex1">Full Name </label>
            <input class="form-control" type="text" name="fullName" formControlName="fullName"
               placeholder="Enter Full Name">  
              <div *ngIf="customerForm.controls['fullName'].invalid && (customerForm.controls['fullName'].dirty || customerForm.controls['fullName'].touched)">
                <div *ngIf="customerForm.controls['fullName'].errors.required">
                  <span class="error sum">Full Name Required.</span>
                </div>
              </div>            
          </div>             
        </div>
        <div class="row">
          <div class="col-6 pb-3 left-p">
            <label for="ex1">Email </label>
          <input class="form-control" type="text" name="email" formControlName="email"
             placeholder="Enter the Email">  
            <div *ngIf="customerForm.controls['email'].invalid && (customerForm.controls['email'].dirty || customerForm.controls['email'].touched)">
              <div *ngIf="customerForm.controls['email'].errors.required">
                <span class="error sum">Email is required.</span>
              </div>
            </div>           
          </div>
          <div class="col-6 pb-3 right-p">
            <label for="ex1">Phone Number (Optional) </label>
            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.India" [maxLength]="15"
            [phoneValidation]="true" [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.National" name="phoneNumber" formControlName="phoneNumber" class="form-control">
          </ngx-intl-tel-input>                             
          </div>
        </div>
        <div class="row">
          <div class="col-6 pb-3">
            <label for="carType">Role</label>
            <select id="role" class="form-control" formControlName="role" name="role"
              placeholder="Select Role" >
              <option [ngValue]="1">
                Operator</option>
            </select>
          </div>
          <div class="col-6 pb-3">
            <label for="carType">Contract</label>
            <select id="role" class="form-control" formControlName="contract" name="contract"
              placeholder="Select Contract" >
              <option [ngValue]="1">
                Moniic EC</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-6 pb-3">
            <label for="carType">Country</label>
            <select id="role" class="form-control" formControlName="country" name="country"
              placeholder="Select Country" >
              <option [ngValue]="1">
                UAE</option>
            </select>
          </div>
          <div class="col-6 pb-3">
            <label for="carType">City</label>
            <select id="role" class="form-control" formControlName="city" name="city"
              placeholder="Select City" >
              <option [ngValue]="1">
                Dubai</option>
            </select>
          </div>
        </div>
       
    <div class="modal-footer">
      <button type="button" class="btn btn-cancel"
        (click)="customerForm.reset(); closeModal('dismiss')">{{'button.cancel'
        | translate}}</button>
      <button type="submit" class="btn btn-save" 
       >CONFIRM</button>
    </div>
  </form> 
</div>
