<div *ngIf="loading">
  <div class="loader-span"></div>
</div>
<div class="modal-body activate">
    <img class="icon" src="./../assets/images/icon-pop-up-confirmation.svg" alt="logout">
    <h3 class="alertText">{{fromParent.head}}</h3>    
    <span class="alertContent"> <p [innerHTML]="fromParent.content"></p></span>
    <div style="display: flex;">
      <a class="button-secondry" (click)="hide()">CANCEL</a>
      &nbsp;&nbsp;
      <a class="btn-confirm" (click)=confirm(fromParent.type)>CONFIRM</a>
    </div>

  </div>
