import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

import { Injectable } from "@angular/core";
import { endpoints } from "../_config/url-endpoint";

@Injectable({
  providedIn: "root",
})
export class CarServices {
  private saveCarVal = new Subject<any>();

  constructor(private http: HttpClient) {}

  createCar(body): Observable<any> {
    return this.http.post(endpoints.cars.carDetailUrl, body);
  }

  carList(payload): Observable<any> {
    let url = `${endpoints.cars.carListUrl}?${payload}`;

    return this.http.get(url);
  }

  carHeaderViewCountList(tenantId): Observable<any> {
    let tenantUrlString = "";
    if (tenantId) {
      tenantUrlString = `?tenantId=${tenantId}`;
    }
    const url = `${endpoints.cars.carCountListUrl}${tenantUrlString}`;
    return this.http.get(url);
  }

  downloadCarExcelFile(queryParams): Observable<any> {
    const url = `${endpoints.cars.downloadCarExcelUrl}?${queryParams}`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(url, { headers, responseType: "blob" });
  }

  getCarDetailByCarId(carId: string): Observable<any> {
    const url = `${endpoints.cars.carDetailUrl}/${carId}`;
    return this.http.get(url);
  }

  updateCar(carId, body): Observable<any> {
    const url = `${endpoints.cars.carDetailUrl}/${carId}`;
    return this.http.put(url, body);
  }

  getPartner(tenantId = null): Observable<any> {
    if (tenantId) {
      return this.http.get(
        `${endpoints.cars.getPartnerUrl}?tenantId=${tenantId}`
      );
    }
    return this.http.get(endpoints.cars.getPartnerUrl);
  }

  changeCarStatus(body): Observable<any> {
    return this.http.post(endpoints.cars.changeCarStatusUrl, body);
  }

  public setRefreshCar(data: any) {
    this.saveCarVal.next({ result: data });
  }

  public getRefreshCar(): Observable<any> {
    return this.saveCarVal.asObservable();
  }

  /**
   * Get car category
   *
   * @param {string} [payload]
   * @returns {Observable<any>}
   * @memberof CarServices
   */
  public getCarCategory(payload?: string): Observable<any> {
    let url = `${endpoints.cars.getCarCategoryUrl}`;
    if (payload) {
      url = `${url}?${payload}`;
    }
    return this.http.get(url);
  }

  public carAdmistratorLog(carId): Observable<any> {
    const url = `${endpoints.cars.carAdministratorLogUrl}/${carId}`;
    return this.http.get(url);
  }

  public getChaufferList(carId): Observable<any> {
    const url = `${endpoints.cars.chaufferListUrl}/${carId}`;
    return this.http.get(url);
  }

  public assignedCarToChauffeur(payload): Observable<any> {
    const url = `${endpoints.cars.assignedCarChauffeurUrl}?${payload}`;
    return this.http.get(url);
  }

  /**
   *
   *
   * @param {string} driverId
   * @param {number} pageNumber
   * @param {number} perPage
   * @param {string} [query]
   * @returns {Observable<any>}
   * @memberof CarServices
   */
  public getAssignedCarList(
    driverId: string,
    perPage: number,
    pageNumber: number,
    query?: string
  ): Observable<any> {
    let url = `${endpoints.cars.assignedCarList}/${driverId}?pageNumber=${pageNumber}&perPage=${perPage}`;
    if (query) {
      url = `${url}&search=${query}`;
    }
    return this.http.get(url);
  }

  /**
   *
   *
   * @param {string} driverId
   * @param {string} carId
   * @returns {Observable<any>}
   * @memberof CarServices
   */
  public selectCarForDriving(driverId: string, carId: string): Observable<any> {
    let url = `${endpoints.cars.selectCarForDriving}/${driverId}`;
    return this.http.post(url, {
      carId,
    });
  }
}
