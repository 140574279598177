import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { configImage, localStorageVal, states } from "src/app/_config/config";

import { Location } from "@angular/common";
import { TenantServices } from "src/app/_services/tenant.service";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { routerLinks } from "../../../_config/router-links";

/**
 * Header component
 *
 * @export
 * @class HeaderComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public notification = routerLinks.notification.notificationHome;
  public profile = routerLinks.profile.profileHome;
  public accountSettings = routerLinks.settings.settingHome;
  modalRef: BsModalRef;
  public userDetails: any;
  public subscription: any;
  public profileImage: any;
  public profileImgName: any;
  public notficationList: any;
  public userDefault = configImage.userDefault;
  public notificationsIcon = configImage.notificationsIcon;
  public settingsIcon = configImage.settingsIcon;
  public logoutImage = configImage.logoutImage;
  public notificationSelected: any;
  public totalNotification: any;
  public totalNewNotification = 0;
  item: any;
  public perPage = 10;
  public pageNumber = 1;
  public type: "notification";

  // show go back item
  showGoBackItem: boolean = false;
  // show tenant dropdown
  showTenantDropdown: boolean = true;

  // get role from local storage
  role: string = localStorage.getItem("role");

  // tenant data
  public tenantId: any;
  public tenantDefaultCurrency: any;
  public tenantData: any = [];

  // tslint:disable-next-line: max-line-length
  constructor(
    public router: Router,
    private modalService: BsModalService,
    public translate: TranslateService,
    private location: Location,
    private tenantService: TenantServices
  ) {}

  ngOnInit(): void {
    // get tenant id from local storage
    this.tenantId = localStorage.getItem(localStorageVal.tenantId);

    // this.getUserData();
    // this.getNotificationList({
    //   perPage: this.perPage,
    //   pageNumber: this.pageNumber,
    //   type: this.type,
    // });

    // route check
    this.routeCheck();
    // // tslint:disable-next-line: deprecation
    // this.subscription = this.authService
    //   .getNotificationEvent()
    //   .subscribe((response) => {
    //     this.ngOnInit();
    //   });

    // get tenant data
    this.getTenantData();
  }

  /**
   * Fetch tenant data
   *
   * @memberof HeaderComponent
   */
  getTenantData() {
    this.tenantService.getTenantData().subscribe((response) => {
      this.tenantData = response.result?.rows;
      this.tenantData.filter((x) => {
        if (x.fullName === states[0]) {
          if (
            this.tenantId === null ||
            this.tenantId === undefined ||
            this.tenantId === ""
          ) {
            localStorage.setItem(localStorageVal.tenantId, x.id);

            //update currency
            if (x.id) {
              localStorage.setItem(localStorageVal.currency, x.currency);
              localStorage.setItem(localStorageVal.timeZone, x.timeZone);
              localStorage.setItem(
                localStorageVal.tenantCentreLocation,
                JSON.stringify(x.location)
              );
            }
          }
        }
      });
      this.tenantService.setTenant(this.tenantData);
    });
  }

  /**
   * Handle Tenant change
   *
   * @param {*} event
   * @memberof HeaderComponent
   */
  tenantChange(event: any): void {
    const newTenantId = event.target.value;
    localStorage.setItem(localStorageVal.tenantId, newTenantId);

    // find tenant currency
    const selectedTenant = this.tenantData.find((t) => t.id === newTenantId);

    //update currency
    if (selectedTenant) {
      localStorage.setItem(localStorageVal.currency, selectedTenant.currency);
      localStorage.setItem(localStorageVal.timeZone, selectedTenant.timeZone);
      localStorage.setItem(
        localStorageVal.tenantCentreLocation,
        JSON.stringify(selectedTenant.location)
      );
    }

    window.location.reload();
  }

  /**
   * Get notification list : TODO
   *
   * @param {*} [filterObj]
   * @memberof HeaderComponent
   */
  getNotificationList(filterObj?: any) {
    // TODO
  }

  /**
   * Mark as read : TODO
   *
   * @memberof HeaderComponent
   */
  markAsRead() {
    // TODO
  }

  /**
   * Notification page
   *
   * @memberof HeaderComponent
   */
  notificationPage() {
    this.router.navigate(["/", "notification"]);
    setTimeout(() => {
      this.markAsRead();
    }, 300);
  }

  /**
   * Profile page
   *
   * @memberof HeaderComponent
   */
  settings() {
    this.router.navigate(["/", "settings"]);
  }

  /**
   * Open modal
   *
   * @memberof HeaderComponent
   */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "alert-modal modal-250" })
    );
  }

  /**
   * Go back
   *
   * @memberof HeaderComponent
   */
  goBack() {
    // Perform any custom actions before navigating back
    // Navigate back
    this.location.back();
  }

  /**
   * Logout
   *
   * @memberof HeaderComponent
   */
  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
    this.modalRef.hide();
  }

  getUserData() {}

  /**
   * Route check
   *
   * @memberof HeaderComponent
   */
  routeCheck() {
    // based on route, show/hide header panel options
    this.handleHeaderPanelOptionsDisplay(this.router.url);

    // check route change on every navigation end
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((navEnd: NavigationEnd) => {
        const urlPostfix = navEnd.urlAfterRedirects;
        this.handleHeaderPanelOptionsDisplay(urlPostfix);
      });
  }

  /**
   * Handle header panel options display
   *
   * @param {string} urlPostfix
   * @memberof HeaderComponent
   */
  handleHeaderPanelOptionsDisplay(urlPostfix: string) {
    // for go back button
    if (
      urlPostfix.includes("/partners/partners-detail") ||
      urlPostfix.includes("/driver/driver-detail") ||
      urlPostfix.includes("/customers/customers-detail") ||
      urlPostfix.includes("/car/car-details") ||
      urlPostfix.includes("/booking/booking-detail") ||
      urlPostfix.includes("/reports/reports-detail")
    ) {
      this.showGoBackItem = true;
    } else {
      this.showGoBackItem = false;
    }

    // for tenant dropdown
    if (
      urlPostfix.includes("/partners/partners-detail") ||
      urlPostfix.includes("/driver/driver-detail") ||
      urlPostfix.includes("/car/car-details") ||
      (urlPostfix.includes("/customers") &&
        !urlPostfix.includes("/customers/customers-detail")) ||
      urlPostfix.includes("/booking/booking-detail") ||
      urlPostfix.includes("/reports/reports-detail") ||
      urlPostfix.includes("/feed")
    ) {
      this.showTenantDropdown = false;
    } else {
      this.showTenantDropdown = true;
    }
  }
}
