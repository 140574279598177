import { Component, OnInit, Input } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
   public modalRef: BsModalRef;
  @Input() fromParent;
 
  constructor(private modal: NgbModal) { }

  ngOnInit(): void {
    
  }
  hide() {
    this.modal.dismissAll();
  }
}
