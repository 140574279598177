import { RouterModule, Routes } from '@angular/router';

import { AuthGuardGuard } from '../util/authGuard/auth-guard.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'car',
    loadChildren: () => import('./car/car.module').then(m => m.CarModule),
    canActivate: [AuthGuardGuard]
  },
  
  {
    path: 'booking',
    loadChildren: () => import('./all-bookings/all-bookings.module').then(m => m.AllBookingsModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'driver',
    loadChildren: () => import('./driver-management/driver-management.module').then(m => m.DriverManagementModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
    canActivate: [AuthGuardGuard]
  },

  {
    path: 'partners',
    loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'chauffeurMap',
    loadChildren: () => import('./active-chauffeur-map/active-chauffeur-map.module').then(m => m.ActiveChauffeurMapModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'kpi',
    loadChildren: () => import('./kpi/kpi.module').then(m => m.KpiModule),
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'feed',
    loadChildren: () => import('./live-feed/live-feed.module').then(m => m.LiveFeedModule),
    canActivate: [AuthGuardGuard]
  },
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
