import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

import {AuthServices} from './auth.service';
import {Injectable} from '@angular/core';
import {endpoints} from '../_config/url-endpoint';

@Injectable({
    providedIn: 'root'
})
export class PartnerService {
    private savePartnerVal = new Subject<any>();

    constructor(private http: HttpClient, private authService: AuthServices) {
    }

    createPartner(body): Observable<any> {
        return this.http.post(endpoints.partnerPage.createPartner, body);
    }


    changePartnerStatus(partnerId, body): Observable<any> {
        return this.http.post(endpoints.partnerPage.changePartnerStatus + partnerId, body);
    }

    editPartner(partnerId, body): Observable<any> {
        return this.http.put(endpoints.partnerPage.editPartner + partnerId, body);
    }


    getPartnerDetails(partnerId): Observable<any> {
        return this.http.get(endpoints.partnerPage.getPartnerDetails + partnerId, this.authService.getAccessHeaders());
    }

    getPartner(): Observable<any> {
        return this.http.get(endpoints.partnerPage.getPartner, this.authService.getAccessHeaders());
    }

    getPartners(queryString): Observable<any> {
        let url = `${endpoints.partnerPage.getPartners}?${queryString}`;
        return this.http.get(url, this.authService.getAccessHeaders());
    }

    getPartnerCount(tenantId): Observable<any> {
        let tenantUrlString = '';
        if(tenantId) {
            tenantUrlString = `?tenantId=${tenantId}`;
        }
        let url = `${endpoints.partnerPage.getPartnerCountUrl}${tenantUrlString}`;
        return this.http.get(url);
    }

    downloadFile(queryString): Observable<any> {
        const url = `${endpoints.partnerPage.getPartnerDownloadUrl}?${queryString}`;
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.get(url, {headers, responseType: 'blob'});
    }

    public setRefreshPartner(data: any) {
        this.savePartnerVal.next({result: data});
    }

    public getRefreshPartner(): Observable<any> {
        return this.savePartnerVal.asObservable();
    }

}
