export const routerLinks = {
  booking: {
    profileHome: "/booking",
  },
  profile: {
    profileHome: "/home",
  },
  administration: {
    administrationHome: "/administration",
  },
  partners: {
    partners: "/partners",
  },
  cars: {
    carHome: "/car",
  },
  customers: {
    customersHome: "/customers",
  },
  driverManagement: {
    driverHome: "/driver",
  },
  analytics: {
    analyticsHome: "/analytics",
  },
  notification: {
    notificationHome: "/notification",
  },
  settings: {
    settingHome: "/settings",
  },
  promoCode: {
    promoCodeHome: "/promocode",
  },
  reasonOfCancellation: {
    reasonOfCancellation: "/reason-of-cancellation",
  },
  promotions: {
    promotions: "/promotions",
  },
  company: {
    company: "/company",
  },
  setting: {
    setting: "/settings",
  },
  reports: {
    reports: "/reports",
  },
  ourfleet: {
    ourfleet: "/chauffeurMap",
  },
  kpi: {
    kpi: "/kpi",
  },
  liveFeed: {
    liveFeed: "/feed",
  },
};
