import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(public toastrService: ToastrService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let reqClone;
    if (localStorage.getItem("access_token") != null) {
      reqClone = request.clone({
        setHeaders: { "x-access-token": localStorage.getItem("access_token") },
      });
    } else {
      reqClone = request.clone();
    }
    return next.handle(reqClone).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.clear();
          this.router.navigate(["/"]);
          this.toastrService.warning("Session expired !");
        } else if (error.status === 403) {
          this.toastrService.warning("Access Denied");
        }
        return throwError(error);
      })
    );
  }
}
